




































































































































section {
  main {
    width: 1200px ;
    margin: 70px auto;
    .tab-list {
      margin: 70px 0;
      margin-right: -30px;
      > ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        > li {
          width: 380px;
          margin-right: 30px;
          margin-bottom: 20px;
        }
      }
    }

    .pagination {
      > .container {
        justify-content: center;
      }
    }
  }
}
@media (max-width: 767px) {
  section {
    main{
      width: 100%;
      .tab-list {
        width: 90%;
        padding: 0 10px;
        margin: 0 auto;
        >ul {
          display: block;
          >li {
            margin: 0 auto;
            width: 100%;
            max-width: 490px;
          }
        }
      }
    }
  }
}
